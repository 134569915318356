<template>
  <div>
    <v-row>
      <v-col cols="21" class="d-flex">
        <v-text-field
          class="mr-md-6"
          label="Card Code"
          dense
          outlined
          required
          v-model="cardCode"
          v-on:keyup.enter="getActiveGiftCard"></v-text-field>
        <v-btn style="height: 40px" large @click="getActiveGiftCard" color="accent">Search</v-btn>
      </v-col>
    </v-row>

    <div class="result" v-if="Object.keys(data).length > 0">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="greeny white--text font-weight-bold">
              Result: {{ data.code }}
              <v-spacer></v-spacer>
              <GiftCardPdf
                :disabled="data.status !== 'completed'"
                tooltip-message="PDF access is not allowed for gift cards that are not completed."
                :giftCardCode="data.code" />
            </v-card-title>

            <v-divider class="mt-4"></v-divider>

            <v-list dense>
              <v-list-item v-for="(key, index) in Object.keys(data)" :key="index">
                <v-row>
                  <v-col cols="3">
                    <v-list-item-content
                      ><b> {{ key }}:</b>
                    </v-list-item-content>
                  </v-col>
                  <v-col>
                    <v-list-item-content class=" ">
                      {{ data[key] }}
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TrainplanetApi from "@/util/trainplanet.api";
import GiftCardPdf from "@/components/order/giftCard/GiftCardPdf.vue";
import { VBtn } from "vuetify/lib";

export default {
  components: {
    GiftCardPdf,
  },
  data: () => ({
    cardCode: "",
    data: {},
  }),
  methods: {
    async getActiveGiftCard() {
      try {
        await this.$store.dispatch("loading", true);
        const res = await TrainplanetApi.getActiveGiftCard(this.cardCode);
        this.data = {
          ...res,
          createdBy: res.createdBy ? `${res.createdBy.fullName ?? res.createdBy.name} / ${res.createdBy.email}` : null,
          approvedBy: res.approvedBy
            ? `${res.approvedBy.fullName ?? res.approvedBy.name} / ${res.approvedBy.email}`
            : null,
        };
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    },
  },
};
</script>
